import { ToastContainer } from 'react-toastify'
import AccountSettings from '../components/AccountSettings/AccountSettings'
import Seo from '../components/seo'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import account from '../content/account'
import LoaderPennyLane from '../components/LoaderPennyLane/LoaderPennyLane'

const { meta_title, meta_description, meta_image } = account

const Account = () => {
  return (
    <>
      <AccountSettings />
      <ToastContainer hideProgressBar={true} position="top-center" />
    </>
  )
}

export default withAuthenticationRequired(Account, {
  onRedirecting: () => <LoaderPennyLane isFullPage={true} text="loading..." />,
})

export const Head = () => {
  return (
    <Seo title={meta_title} description={meta_description} image={meta_image} />
  )
}
