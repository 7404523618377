export default {
  meta_title: 'Account — PennyLane',
  meta_description:
    'A Python library for quantum machine learning, automatic differentiation, and optimization of hybrid quantum-classical computations. Use multiple hardware devices, alongside TensorFlow or PyTorch, in a single computation.',
  meta_image: 'https://assets.cloud.pennylane.ai/pennylane_website/generic/thumbnail.png',
  welcome_card: {
    title: 'Welcome to PennyLane!',
    description:
      "We're glad you're here. You've just joined a vibrant community of researchers, developers, educators, students, and more. Let's get your profile set up fully so that we can make customized recommendations and help you discover new ideas faster.",
  },
  certificates_card: {
    certificates_text: `Newly earned certificates will appear in the **Pending Certificates** section. Once a certificate is accepted, it will appear in the **Your Certificates** section. You can choose to share the link to your verifiable certificate, download it, or add it to your PennyLane profile page.

If you have an issue with a certificate, please contact support@pennylane.ai.`,
    preview_checkbox_text: 'Yes, this information is correct.',
  },
  email_disclaimer:
    'If you need to change your email, please contact [support@pennylane.ai](mailto:support@pennylane.ai).',
  password_card:
    'To change your password, click the link below and you will be directed to a new window to complete the request.',
  delete_account: {
    warning: `Clicking 'delete account' will permanently delete your account and all data associated with it. Please download your certificates before deleting your account.`,
    subtext: 'You’ll get a chance to confirm your choice.',
    modal_text: 'Are you sure you want to delete your account?',
    successMessage: 'Account was successfully deleted',
  },
  generalErrorMessage: 'Something went wrong, please try again later.',
}
