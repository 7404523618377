import { IPendingCertificate } from '../CertificateManagementSection'
import PendingCertificate from '../PendingCertificate/PendingCertificate'

export const PENDING_CERTIFICATES_TEST_ID = 'pending-certificates'

interface IPendingCertificatesProps {
  pendingCertificates: IPendingCertificate[]
  userEmail: string
  userFirstName: string
  userLastName: string
  userId: string
}

const PendingCertificates = ({
  pendingCertificates,
  userEmail,
  userFirstName,
  userLastName,
  userId,
}: IPendingCertificatesProps) => {
  return (
    <div data-testid={PENDING_CERTIFICATES_TEST_ID}>
      <h3 className="text-base font-medium font-roboto text-[#232323] mb-3">
        Pending certificates:
      </h3>
      <div className="border-b border-b-grey-6 mb-2">
        <p className="mb-1 text-[14px] text-[#232323] font-roboto font-normal">
          Certificate Name
        </p>
      </div>
      <ul>
        {pendingCertificates.map((pendingCertificate) => (
          <PendingCertificate
            key={`${pendingCertificate.definition.slug}-pending-certificate`}
            pendingCertificate={pendingCertificate}
            userEmail={userEmail}
            userFirstName={userFirstName}
            userLastName={userLastName}
            userId={userId}
          />
        ))}
      </ul>
    </div>
  )
}

export default PendingCertificates
