import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Modal from 'react-modal'
import { gql, useMutation } from '@apollo/client'
import { Checkbox, Button } from 'component-library'
import { SelectedView } from '../PendingCertificate'

export const ACCEPT_CERTIFICATE_BTN = 'accept-certificate-btn'
export const PREVIEW_CONFIRM_CHECKBOX = 'preview-confirm-checkbox'

interface ICertificatePreviewProps {
  certificateEmail: string
  certificateId: string
  certificatePreviewUrl: string
  certificateSlug: string
  isLoading: boolean
  toggleIsLoading: Dispatch<SetStateAction<boolean>>
  updateSelectedView: Dispatch<SetStateAction<SelectedView>>
}

export const ACCEPT_PENDING_CERTIFICATE = gql(`
  mutation AcceptPendingCertificate($awardId: CertificateAwardID!) {
    acceptPendingCertificate(awardId: $awardId) {
      userError {
        field
        message
      }
    }
  }
`)

const CertificatePreview = ({
  certificateEmail,
  certificateId,
  certificatePreviewUrl,
  certificateSlug,
  isLoading,
  toggleIsLoading,
  updateSelectedView,
}: ICertificatePreviewProps) => {
  const [confirmFinalCertificate, toggleConfirmFinalCertificate] =
    useState<boolean>(false)
  const [isModalOpen, toggleIsModalOpen] = useState<boolean>(false)
  const [errorMessage, updateErrorMessage] = useState<string>('')

  const [acceptPendingCertificateMutation] = useMutation(
    ACCEPT_PENDING_CERTIFICATE
  )

  useEffect(() => {
    if (errorMessage) {
      const clearMessageTimeout = setTimeout((): void => {
        updateErrorMessage('')
        clearTimeout(clearMessageTimeout)
      }, 3000)
    }
  }, [errorMessage])

  const acceptPendingCertificate = async () => {
    toggleIsLoading(true)
    try {
      const response = await acceptPendingCertificateMutation({
        variables: {
          awardId: certificateId,
        },
      })
      if (response.data.acceptPendingCertificate.userError) {
        updateErrorMessage(
          response.data.acceptPendingCertificate.userError[0].message
        )
      } else {
        updateSelectedView('certificate-accepted')
      }
      toggleIsLoading(false)
    } catch (error) {
      console.error(error)
      toggleIsLoading(false)
      updateErrorMessage('Server error, please try again.')
    }
  }

  return (
    <div className="PendingCertificate__preview">
      <p className="font-roboto text-center text-[#232323] text-base">
        Certificate preview
      </p>
      <button className="w-full mb-4" onClick={() => toggleIsModalOpen(true)}>
        <img src={certificatePreviewUrl} alt="" className="w-full" />
      </button>
      <p className="text-center font-normal text-base text-[#232323]">
        Email associated with this certificate: <br /> {certificateEmail}
      </p>
      <Checkbox
        testId={PREVIEW_CONFIRM_CHECKBOX}
        isChecked={confirmFinalCertificate}
        fieldName="confirm-information"
        label="Yes, this information is correct."
        onChange={() => toggleConfirmFinalCertificate(!confirmFinalCertificate)}
        className="mb-4"
      />
      {errorMessage && (
        <p className="serverError !mt-0 mb-4">
          <i className="bx bxs-error-circle"></i> {errorMessage}
        </p>
      )}
      <Button
        type="primary"
        label="Confirm"
        className="w-full mb-3"
        testId={ACCEPT_CERTIFICATE_BTN}
        disabled={!confirmFinalCertificate || isLoading}
        onClick={() => acceptPendingCertificate()}
      />
      <Button
        type="text"
        label="Back"
        className="w-full"
        onClick={() => {
          updateSelectedView('certificate-form')
        }}
      />
      <Modal
        id={`${certificateSlug}-image-preview`}
        isOpen={isModalOpen}
        className="fixed p-[40px] bg-white rounded-[5px] w-[90%] left-[5%] h-[90%] top-[5%]"
        preventScroll={true}
      >
        <button
          className="absolute right-3 top-2"
          onClick={() => toggleIsModalOpen(false)}
          aria-label="close modal"
        >
          <i className="bx bx-x text-xl" />
        </button>
        <img src={certificatePreviewUrl} alt="" className="w-full h-full" />
      </Modal>
    </div>
  )
}

export default CertificatePreview
