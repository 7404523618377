import { useEffect, useState } from 'react'
import { get } from 'lodash'
import { gql, useLazyQuery } from '@apollo/client'
import AccountContent from '../../content/account'
import LinkComponent from '../LinkComponent/LinkComponent'
import MarkdownText from '../MarkdownText/MarkdownText'
import LoaderSpinner from '../LoaderSpinner/LoaderSpinner'
import AwardedCertificates from './AwardedCertificates/AwardedCertificates'
import PendingCertificates from './PendingCertificates/PendingCertificates'

const { certificates_card } = AccountContent
const { certificates_text } = certificates_card

export const GET_CERTIFICATES = gql(`
  query GetCertificates($userId: UserID!){
    profile(userId: $userId) {
      awardedCertificates {
        acceptedAt
        awardedAt
        id
        imageUrl
        linkedinShareUrl
        obCredentialUrl
        public
        publicSharePermalink
        definition {
          description
          slug
          title
        }
      }
      pendingCertificates {
        awardedAt
        id
        definition {
          description
          slug
          title
        }
        recipientInfo {
          email
          fullName
        }
      }
    }
  }
`)

export interface IAwardedCertificate {
  acceptedAt: string
  awardedAt: string
  id: string
  imageUrl: string
  linkedinShareUrl: string
  obCredentialUrl: string
  public: boolean
  publicSharePermalink: string
  definition: {
    description: string
    slug: string
    title: string
  }
}

export interface IPendingCertificate {
  awardedAt: string
  id: string
  definition: {
    description: string
    slug: string
    title: string
  }
  recipientInfo: {
    email: string
    fullName: string | null
  }
}

interface ICertificateManagementSectionProps {
  handle: string
  userEmail: string
  userFirstName: string
  userLastName: string
  userId: string
}

export const PROFILE_LINK_TEST_ID =
  'certificate-management-section-profile-link'

const CertificateManagementSection = ({
  handle,
  userEmail,
  userFirstName,
  userLastName,
  userId,
}: ICertificateManagementSectionProps) => {
  const [awardedCertificates, setAwardedCertificates] = useState<
    IAwardedCertificate[] | []
  >([])
  const [pendingCertificates, setPendingCertificates] = useState<
    IPendingCertificate[] | []
  >([])

  const [getCertificates, { data, loading, error }] = useLazyQuery(
    GET_CERTIFICATES,
    {
      variables: { userId },
    }
  )

  useEffect(() => {
    if (userId) {
      getCertificates()
    }
  }, [userId])

  useEffect(() => {
    if (data) {
      setAwardedCertificates(get(data, 'profile.awardedCertificates', []))
      setPendingCertificates(get(data, 'profile.pendingCertificates', []))
    }
  }, [data, error])

  return (
    <div className="AccountCard" id="manage-certificates">
      <div className="flex justify-between mb-4">
        <h2 className="!border-none !mb-0 !pb-0">Manage Certificates</h2>
        <LinkComponent
          href={`/profile/${handle}`}
          className="my-auto text-grey-5 hover:text-[#0074e0] transition-colors"
          testId={PROFILE_LINK_TEST_ID}
          aria-label="View my profile"
        >
          <i className="bx bx-link-external text-[1.75rem]" />
        </LinkComponent>
      </div>

      <hr className="mb-6 border-grey-5" />

      {loading ? (
        <div className="flex">
          <LoaderSpinner className="m-auto" />
        </div>
      ) : (
        <MarkdownText text={certificates_text} className="text-[#757575]" />
      )}
      {(awardedCertificates.length > 0 || pendingCertificates.length > 0) &&
        !loading && (
          <>
            {awardedCertificates.length > 0 && (
              <div className={pendingCertificates.length > 0 ? 'mb-6' : ''}>
                <AwardedCertificates
                  awardedCertificates={awardedCertificates}
                />
              </div>
            )}
            {pendingCertificates.length > 0 && (
              <PendingCertificates
                pendingCertificates={pendingCertificates}
                userEmail={userEmail}
                userFirstName={userFirstName}
                userLastName={userLastName}
                userId={userId}
              />
            )}
          </>
        )}
    </div>
  )
}

export default CertificateManagementSection
