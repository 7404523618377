import { Button } from 'component-library'
import SmoothCollapse from 'react-smooth-collapse'
import './AccountFormCard.scss'
import { SyntheticEvent } from 'react'

interface IAccountFormCardProps {
  title: string
  cardId?: string
  onSubmit?: (e: SyntheticEvent) => void
  clearData?: () => void
  children: JSX.Element
  showButtons: boolean
  isSavedChanges?: boolean
  hasError?: boolean
  errorMessage?: string
  submitDisabled?: boolean
  className?: string
}

const AccountFormCard = ({
  title,
  cardId = '',
  className = '',
  onSubmit,
  clearData,
  children,
  showButtons = false,
  isSavedChanges = false,
  hasError = false,
  errorMessage = '',
  submitDisabled = false,
}: IAccountFormCardProps) => {
  return (
    <form
      className={`AccountCard ${className} ${
        hasError ? `AccountCard--warning` : ''
      }`}
      name={cardId}
      id={cardId}
      onSubmit={onSubmit}
      data-testid="account-card"
    >
      <h2>{title}</h2>
      {children}
      <SmoothCollapse expanded={showButtons}>
        <div className="AccountCard__buttons">
          <Button
            onClick={clearData}
            label="Cancel"
            buttonType="reset"
            type="secondary"
            variation="neutral"
            className="mb-4 md:mb-0 md:mr-4"
            data-testid="cancel-button"
          />
          <Button
            label="Save changes"
            onSubmit={onSubmit}
            type="primary"
            buttonType="submit"
            data-testid="submit-button"
            className="mb-4"
            disabled={submitDisabled}
          />
        </div>
      </SmoothCollapse>
      <div className="AccountCard__messages">
        {isSavedChanges && (
          <p className="savedChanges">
            <i className="bx bxs-check-circle"></i> Changes successfully saved
          </p>
        )}
        {hasError && (
          <p className="serverError">
            <i className="bx bxs-error-circle"></i>{' '}
            {errorMessage ? errorMessage : 'Server error, please try again.'}
          </p>
        )}
      </div>
    </form>
  )
}

export default AccountFormCard
