import { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { Select } from 'component-library'
import { SingleValue } from 'react-select'
import { useAuth0 } from '@auth0/auth0-react'
import { Tooltip } from 'react-tooltip'
import copy from 'copy-to-clipboard'
import LinkComponent from '../../LinkComponent/LinkComponent'
import { IAwardedCertificate } from '../CertificateManagementSection'
import { getCurrentEnvConfig } from '../../../../config'
import './AwardedCertificate.scss'

const config = getCurrentEnvConfig()

export const AWARDED_CERTIFICATE_TEST_ID = 'awarded-certificate'
export const LINKEDIN_ENABLED_TEST_ID = 'linkedin-enabled'
export const LINKEDIN_DISABLED_TEST_ID = 'linkedin-disabled'
export const COPY_CERTIFICATE_URL_BTN = 'copy-certificate-url-btn'

interface IAwardedCertificateProps {
  awardedCertificate: IAwardedCertificate
}

export const UPDATE_CERTIFICATE_AWARD_VISIBILITY = gql(`
  mutation UpdateCertificateAwardVisibility($awardId: CertificateAwardID!, $public: Boolean!) {
    updateCertificateAwardVisibility(awardId: $awardId, public: $public) {
      userError {
        field
        message
      }
      payload {
        publicSharePermalink
      }
    }
  }
`)

const AwardedCertificate = ({
  awardedCertificate,
}: IAwardedCertificateProps) => {
  const { getAccessTokenSilently } = useAuth0()
  const [isPublic, toggleIsPubic] = useState<boolean>(awardedCertificate.public)
  const [publicSharePermalink, updatePublicSharePermalink] = useState<
    string | null
  >(awardedCertificate.publicSharePermalink)
  const [isLoading, toggleIsLoading] = useState<boolean>(false)
  const [copySharableLinkText, updateCopySharableLinkText] =
    useState<string>('Get shareable link')

  const [updateCertificateAwardVisibility] = useMutation(
    UPDATE_CERTIFICATE_AWARD_VISIBILITY
  )

  const handleChange = async (
    option: SingleValue<{
      value: boolean
      label: string
    }>
  ) => {
    if (option) {
      toggleIsLoading(true)
      try {
        const response = await updateCertificateAwardVisibility({
          variables: {
            awardId: awardedCertificate.id,
            public: option.value,
          },
        })
        if (
          !response.data.updateCertificateAwardVisibility.userError &&
          response.data.updateCertificateAwardVisibility.payload
        ) {
          toggleIsPubic(option.value)
          updatePublicSharePermalink(
            response.data.updateCertificateAwardVisibility.payload
              .publicSharePermalink
          )
          toast.success(
            `${awardedCertificate.definition.title} is now ${
              option.value ? 'public' : 'private'
            }!`
          )
        } else {
          toast.error(
            response.data.updateCertificateAwardVisibility.userError[0].message
          )
        }
        toggleIsLoading(false)
      } catch (e) {
        console.error(e)
        toast.error('There was a server error')
        toggleIsLoading(false)
      }
    }
  }

  const downloadImage = (imageUrl: string) => {
    const aElement = document.createElement('a')
    aElement.setAttribute('download', awardedCertificate.definition.slug)
    aElement.href = imageUrl
    aElement.setAttribute('target', '_blank')
    aElement.click()
    URL.revokeObjectURL(imageUrl)
  }

  const onDownloadClick = async () => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.auth0.audience,
        },
      })
      const certificateImageResponse = await fetch(
        awardedCertificate.obCredentialUrl,
        {
          headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'image/svg+xml',
          },
        }
      )
      const blob = await certificateImageResponse.blob()
      downloadImage(URL.createObjectURL(blob))
    } catch (e) {
      console.error(e)
      toast.error('There was an error downloading your certificate')
    }
  }

  const copyToClipboard = () => {
    if (publicSharePermalink) {
      copy(publicSharePermalink)
      updateCopySharableLinkText('Copied to clipboard')
      setTimeout(() => {
        updateCopySharableLinkText('Get shareable link')
      }, 750)
    }
  }

  return (
    <li
      className="flex mb-3 justify-between h-auto md:h-12 gap-4 flex-col md:!flex-row"
      data-testid={AWARDED_CERTIFICATE_TEST_ID}
    >
      <span className="AwardedCertificate__title">
        {awardedCertificate.definition.title}
      </span>
      <div className="flex gap-4 justify-end">
        <button
          className="my-auto"
          data-testid={COPY_CERTIFICATE_URL_BTN}
          data-tooltip-id="infoTip"
          data-tooltip-content={
            isPublic && !isLoading ? copySharableLinkText : ''
          }
          onClick={() => copyToClipboard()}
          disabled={!(isPublic && !isLoading)}
          aria-label={`Copy ${awardedCertificate.definition.title} url`}
        >
          <i
            className={`bx bx-link-alt ${
              isPublic && !isLoading
                ? 'text-grey-2 cursor-pointer'
                : 'text-[#A6A6A6] cursor-not-allowed'
            } text-xl`}
          />
        </button>
        {isPublic && !isLoading ? (
          <LinkComponent
            href={awardedCertificate.linkedinShareUrl}
            className="my-auto"
            testId={LINKEDIN_ENABLED_TEST_ID}
            data-tooltip-id="infoTip"
            data-tooltip-content="Add to your LinkedIn profile"
            aria-label={`Add ${awardedCertificate.definition.title} to your LinkedIn profile`}
          >
            <i className="bx bxl-linkedin-square text-grey-2 text-xl" />
          </LinkComponent>
        ) : (
          <div
            className="cursor-not-allowed my-auto"
            data-testid={LINKEDIN_DISABLED_TEST_ID}
          >
            <i className="bx bxl-linkedin-square text-[#A6A6A6] text-xl" />
          </div>
        )}
        <a
          className="my-auto cursor-pointer"
          onClick={() => onDownloadClick()}
          data-tooltip-id="infoTip"
          data-tooltip-content="Download certificate"
          aria-label={`Download ${awardedCertificate.definition.title}`}
          tabIndex={0}
        >
          <i className="bx bxs-download text-grey-2 text-xl" />
        </a>
        <div className="AwardedCertificate__select-wrapper">
          <Select
            testId="public-select"
            isLoading={isLoading}
            loadingMessage={() => 'Loading...'}
            formName="certificateForm"
            fieldName="public"
            fieldLabel="Public"
            fieldLabelHidden
            isSearchable={false}
            defaultValue={{
              value: isPublic,
              label: isPublic ? 'Public' : 'Private',
            }}
            options={[
              {
                value: true,
                label: 'Public',
              },
              {
                value: false,
                label: 'Private',
              },
            ]}
            value={{
              value: isPublic,
              label: isPublic ? 'Public' : 'Private',
            }}
            onChange={(newValue) =>
              handleChange(
                newValue as SingleValue<{
                  value: boolean
                  label: string
                }>
              )
            }
          />
        </div>
      </div>
      <Tooltip id="infoTip" />
    </li>
  )
}

export default AwardedCertificate
