import AwardedCertificate from '../AwardedCertificate/AwardedCertificate'
import { IAwardedCertificate } from '../CertificateManagementSection'

export const AWARDED_CERTIFICATES_TEST_ID = 'awarded-certificates'

interface IAwardedCertificatesProps {
  awardedCertificates: IAwardedCertificate[]
}

const AwardedCertificates = ({
  awardedCertificates,
}: IAwardedCertificatesProps) => {
  return (
    <div data-testid={AWARDED_CERTIFICATES_TEST_ID}>
      <h3 className="text-base font-medium font-roboto text-[#232323] mb-3">
        Your certificates:
      </h3>
      <div className="border-b border-b-grey-6 mb-2 flex justify-between">
        <p className="mb-1 text-sm text-[#232323] font-roboto font-normal">
          Certificate Name
        </p>
        <p className="mb-1 text-sm text-[#232323] font-roboto font-normal hidden md:!block">
          Visibility
        </p>
      </div>
      <ul>
        {awardedCertificates.map((awardedCertificate) => (
          <AwardedCertificate
            key={`${awardedCertificate.definition.slug}-pending-certificate`}
            awardedCertificate={awardedCertificate}
          />
        ))}
      </ul>
    </div>
  )
}

export default AwardedCertificates
