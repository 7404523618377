import { Dispatch, SetStateAction, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Tooltip } from 'react-tooltip'
import { useAuth0 } from '@auth0/auth0-react'
import { Button, Input } from 'component-library'
import { SelectedView } from '../PendingCertificate'
import { getCurrentEnvConfig } from '../../../../../config'

const config = getCurrentEnvConfig()

export const SUBMIT_DETAILS_FORM = 'submit-details-form-btn'

export const GET_PREVIEW_CERTIFICATE_IMAGE = gql(`
  mutation GetPreviewCertificateImage($awardId: CertificateAwardID!, $email: String!, $fullName: String!) {
    updateCertificateRecipientInfo(awardId: $awardId, email: $email, fullName: $fullName) {
      payload {
        imageUrl
      }
      userError {
        field
        message
      }
    }
  }
`)

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

interface IDetailsFormProps {
  certificateEmail: string
  certificateId: string
  certificateName: string
  isLoading: boolean
  toggleIsLoading: Dispatch<SetStateAction<boolean>>
  updateCertificateEmail: Dispatch<SetStateAction<string>>
  updateCertificateName: Dispatch<SetStateAction<string>>
  updateCertificatePreviewUrl: Dispatch<SetStateAction<string>>
  updateSelectedView: Dispatch<SetStateAction<SelectedView>>
}

const DetailsForm = ({
  certificateEmail,
  certificateName,
  certificateId,
  isLoading,
  toggleIsLoading,
  updateCertificateEmail,
  updateCertificateName,
  updateCertificatePreviewUrl,
  updateSelectedView,
}: IDetailsFormProps) => {
  const { getAccessTokenSilently } = useAuth0()

  const [errorMessage, updateErrorMessage] = useState<string>('')

  const [getPreviewCertificateImage] = useMutation(
    GET_PREVIEW_CERTIFICATE_IMAGE
  )

  const createCertificatePreview = async () => {
    toggleIsLoading(true)
    try {
      const response = await getPreviewCertificateImage({
        variables: {
          fullName: certificateName.trim(),
          email: certificateEmail.trim(),
          awardId: certificateId,
        },
      })
      if (response.data.updateCertificateRecipientInfo.userError) {
        updateErrorMessage(
          Array.isArray(response.data.updateCertificateRecipientInfo.userError)
            ? response.data.updateCertificateRecipientInfo.userError[0].message
            : response.data.updateCertificateRecipientInfo.userError.message
        )
      } else {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: config.auth0.audience,
          },
        })
        const privateCertImageResponse = await fetch(
          response.data.updateCertificateRecipientInfo.payload.imageUrl,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        const blob = await privateCertImageResponse.blob()
        const previewImageUrl = URL.createObjectURL(blob)
        updateCertificatePreviewUrl(previewImageUrl)
        updateSelectedView('certificate-preview')
      }
      toggleIsLoading(false)
    } catch (error) {
      console.error(error)
      toggleIsLoading(false)
      updateErrorMessage('Server error, please try again.')
    }
  }

  return (
    <div className="PendingCertificate__details-form">
      <p className="font-roboto text-center text-[#232323] text-base">
        Enter your information
      </p>
      <form name="certificate-details" id="certificate-details">
        <Input
          fieldLabel="Name displayed on your certificate"
          fieldName="name"
          formName="name"
          type="text"
          value={certificateName}
          updateValue={(value) => {
            updateCertificateName(value as string)
            if (errorMessage) updateErrorMessage('')
          }}
          maxLength={51}
          className="mb-4"
          disabled={isLoading}
        />
        <Input
          fieldLabel={
            <div className="flex align-middle">
              <span>Email associated with your certificate</span>{' '}
              <i
                className="bx bxs-info-circle text-grey-5 cursor-pointer ml-2 my-auto"
                data-tip
                data-tooltip-id="infoTip"
                data-for="infoTip"
                aria-label="Your certificate is verifiable with the email that you enter. You have the option to choose an email that is not connected to your PennyLane account."
                data-tooltip-content="Your certificate is verifiable with the email that you enter. You have the option to choose an email that is not connected to your PennyLane account."
              />
              <Tooltip id="infoTip" className="!w-44" />
            </div>
          }
          fieldName="email"
          formName="email"
          type="email"
          value={certificateEmail}
          updateValue={(value) => {
            updateCertificateEmail(value as string)
            if (errorMessage) updateErrorMessage('')
          }}
          errorText={
            !certificateEmail.toLowerCase().match(EMAIL_REGEX)
              ? 'Please enter a valid email.'
              : ''
          }
          rightIcon={
            !certificateEmail.toLowerCase().match(EMAIL_REGEX)
              ? 'bx bxs-error-circle'
              : ''
          }
          iconPosition="rightIcon"
          className="mb-4"
          disabled={isLoading}
        />
      </form>
      {errorMessage && (
        <p className="serverError !mt-0 mb-4">
          <i className="bx bxs-error-circle"></i> {errorMessage}
        </p>
      )}
      <Button
        type="primary"
        label="Preview Certificate"
        className="w-full mb-3"
        disabled={
          !certificateName ||
          certificateName.length > 51 ||
          !certificateEmail ||
          !certificateEmail.toLowerCase().match(EMAIL_REGEX) ||
          isLoading
        }
        onClick={() => createCertificatePreview()}
        loading={isLoading}
        testId={SUBMIT_DETAILS_FORM}
      />
      <Button
        type="text"
        label="Back"
        className="w-full"
        onClick={() => {
          updateSelectedView('certificate-details')
        }}
      />
    </div>
  )
}

export default DetailsForm
